import React, { useState } from 'react';

const helpContent = {
  n: "Number of Overs (N): Total overs originally scheduled for the innings.",
  s: "Team 1's Total Score (S): The score achieved by Team 1 at the end of its innings",
  tournament_overs: "The number of overs originally scheduled in the match. This will match with n, unless the match is delayed and starts as a curtailed overs match.",
  g50: "Average Score in 50 overs (G50): The average score for a complete 50-over innings, based on historical data. (245 for 50 overs)",
  matchScenarios: "Match Scenarios: Details of any interruptions during the match, including overs before and after the interruption, and wickets lost.",
  team: "Select 1 for interruption in first innings and 2 for interruption in second innings",
  balls_before: "Number of balls left in the innings at the time of interruption",
  balls_after: "Number of balls left in the innings at the time of resumption of play",
  wickets: "Wickets lost at the time of interruption"
};


function App() {
  const [formData, setFormData] = useState({
    n: '',
    s: '',
    tournament_overs: '',
    g50: '',
    match_scenarios: []
  });

  const [targetScore, setTargetScore] = useState(null);
  const [selectedField, setSelectedField] = useState(null);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: parseInt(e.target.value) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://backend.crikit.io/dls', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      const result = await response.json();
      setTargetScore(result.DLS.target);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const addMatchScenario = () => {
    const newScenario = { team: '', balls_before: '', balls_after: '', wickets: '' };
    setFormData({ ...formData, match_scenarios: [...formData.match_scenarios, newScenario] });
  };

  const handleScenarioChange = (index, e) => {
    const updatedScenarios = formData.match_scenarios.map((scenario, i) => {
      if (i === index) {
        return { ...scenario, [e.target.name]: parseInt(e.target.value) };
      }
      return scenario;
    });

    setFormData({ ...formData, match_scenarios: updatedScenarios });
  };

  return (
    <div className="container mt-5">
      <div className="row text-center justify-content-center">
        <h1>DLS Calculator</h1>
      </div>

      <div className="row py-5 justify-content-center">
        <div className="col-md-6">
          {targetScore !== null && (
            <div className="alert alert-success text-center">
              <strong>Target Score:</strong> {targetScore}
            </div>
          )}
          <form onSubmit={handleSubmit}>
          <div className="mb-3">
              <label className="form-label">Original Number of Overs in the Tournament: </label>
              <input className="form-control" type="number" name="tournament_overs" value={formData.tournament_overs} onChange={handleInputChange} onFocus={() => setSelectedField('tournament_overs')}/>
            </div>
            <div className="mb-3">
              <label className="form-label">Number of overs the match started with (N): </label>
              <input className="form-control" type="number" name="n" value={formData.n} onChange={handleInputChange} onFocus={() => setSelectedField('n')} />
            </div>
            <div className="mb-3">
              <label className="form-label">Team batting first total score (S): </label>
              <input className="form-control" type="number" name="s" value={formData.s} onChange={handleInputChange} onFocus={() => setSelectedField('s')}/>
            </div>
            <div className="mb-3">
              <label className="form-label">Average Score in Tournament overs (Gxx): </label>
              <input className="form-control" type="number" name="g50" value={formData.g50} onChange={handleInputChange} onFocus={() => setSelectedField('g50')} />
            </div>
            <div className="d-flex flex-column">
              {formData.match_scenarios.map((scenario, index) => (
                <div key={index}>
                  <h4 className="text-center mb-4">{`Interruption ${index + 1}`}</h4>
                  <input
                    className="form-control mb-2"
                    type="number"
                    name="team"
                    min="1"
                    max="2"
                    value={scenario.team}
                    onChange={(e) => handleScenarioChange(index, e)}
                    placeholder="Team"
                  onFocus={() => setSelectedField('team')}
                  />
                  <input
                    className="form-control mb-2"
                    type="number"
                    name="balls_before"
                    value={scenario.balls_before}
                    onChange={(e) => handleScenarioChange(index, e)}
                    placeholder="Balls left before interruption"
                  onFocus={() => setSelectedField('balls_before')}
                  />
                  <input
                    className="form-control mb-2"
                    type="number"
                    name="balls_after"
                    value={scenario.balls_after}
                    onChange={(e) => handleScenarioChange(index, e)}
                    placeholder="Balls left after interruption"
                  onFocus={() => setSelectedField('balls_after')}
                  />
                  <input
                    className="form-control mb-2"
                    type="number"
                    name="wickets"
                    min="0"
                    max="10"
                    value={scenario.wickets}
                    onChange={(e) => handleScenarioChange(index, e)}
                    placeholder="Wickets"
                  onFocus={() => setSelectedField('wickets')}
                  />
                </div>
              ))}
              <button type="button" className="btn btn-primary mb-3" onClick={addMatchScenario}>Add Scenario</button>
            </div>
            <hr />
            <div className="mb-5 d-flex flex-column justify-content-center">
              <button className="btn btn-success" type="submit">Calculate</button>
            </div>
          </form>
        </div>
        <div className="col-md-3">
          <h5><a href="https://crikit.freshdesk.com/a/solutions/articles/43000722332">Help</a></h5>
          {selectedField && (
            <div>
              <p className="fst-italic text-muted small">{helpContent[selectedField]}</p>
            </div>
          )}
        </div>
      </div>

      <div className="row text-center">
        <div className="col-md-12">
          <a href="http://crikit.io"><img src="https://s3.amazonaws.com/crikit/PoweredByCrikIT.png" height="22" alt="CrikIT" /></a>
        </div>
      </div>
    </div>
  );
}

export default App;
